import BaseController from '@/service/BaseService'
import ContributeRequest from '@/request/Business/ContributeRequest'
import { confirmTip, errorTip, successTip } from '@/utils/Tip'

const base = new BaseController()

export default {
  data () {
    return {
      showDialog: false, // 是否显示弹窗
      tableLoad: false, // 表格加载
      scene: 0, // 场景: 0表示弹框为添加，1表示弹框为修改
      // 表单信息
      form: {
        Name: '',
        Phone: '',
        Domain: '',
        Thesis: '',
        Email: '',
        Pdf: '',
        Word: '',
        EducationalBackground: '',
        Standing: '',
        ParticipationMode: ''
      },
      // 表单验证规则
      rules: {
        Name: [{
          required: true,
          message: '请输入姓名'
        }],
        Phone: [{
          required: true,
          message: '请输入联系方式'
        }],
        Domain: [{
          required: true,
          message: '请输入网站域名'
        }],
        Thesis: [{
          required: true,
          message: '请输入论文标题'
        }],
        Email: [{
          required: true,
          message: '请输入邮箱'
        }],
        Word: [{
          required: true,
          message: '请输入word'
        }]
      },

      // 分页信息[前端控制单页数量]
      pageData: {
        Page: 1,
        Limit: 10,
        Total: 0,
        List: [],
        Select: {
          Status: {}
        }
      },

      // 表单筛选
      searchForm: {
        Name: null,
        Phone: null,
        Domain: null,
        Thesis: null,
        Email: null,
        EducationalBackground: null,
        Standing: null,
        ParticipationMode: null,
        Status: null
      }
    }
  },
  // 初始化函数
  created: async function () {
    await base.init(this, new ContributeRequest()).getList(this.pageData, this.searchForm)
  },
  methods: {
    // 导入事件
    importClick: async function ({ file }) {
      await base.importClick(file)
    },
    // 导出事件
    exportClick: async function () {
      await base.exportClick()
    },
    // 提交事件
    submitClick: async function () {
      await base.submitClick()
    },
    // 取消事件
    cancelDialog: async function () {
      await base.cancelDialog()
    },
    // 删除事件
    deleteClick: async function (item) {
      await base.deleteClick(item)
    },
    copyClick: async function (item) {
      await navigator.clipboard.writeText(item.Thesis)
      successTip(this, '复制成功')
    },
    // 核销
    handleClick: async function (item) {
      // 询问框
      const status = await confirmTip(this, '确定核销吗?')
      if (!status) return false
      // 发起请求
      const result = await base.request.handlePost(item)
      if (result.code !== 200) errorTip(this, result.message)
      // 更新表格数据
      await base.getList(this.pageData, this.searchForm)
      successTip(this, result.message)
    },
    // 搜索事件
    searchClick: async function () {
      await base.getList()
    },
    // 重置事件
    resetClick: async function () {
      await base.resetClick()
    },
    // 添加事件
    addClick: function () {
      base.addClick()
    },
    // 分页事件
    pagingClick: async function (val) {
      await base.pagingClick(val)
    },
    // 编辑事件
    editClick: async function (item) {
      await base.editClick(item)
    },
    // 子组件修改父组件data
    changDataValue: function (field, value) {
      this.form[field] = value
    }
  }
}
