<template>
  <div class="manage">

    <!--    搜索区域开始   -->
    <el-collapse>
      <el-collapse-item title="搜索" name="1">
        <el-form :model="searchForm"
                 :inline="true"
                 label-width="80px">
          <el-form-item label="姓名" prop="name">
            <el-input placeholder="请输入姓名" v-model="searchForm.Name" size="small"/>
          </el-form-item>
          <el-form-item label="手机" prop="phone">
            <el-input placeholder="请输入手机" v-model="searchForm.Phone" size="small"/>
          </el-form-item>
          <el-form-item label="网站域名" prop="domain">
            <el-input placeholder="请输入网站域名" v-model="searchForm.Domain" size="small"/>
          </el-form-item>
          <el-form-item label="标题" prop="thesis">
            <el-input placeholder="请输入标题" v-model="searchForm.Thesis" size="small"/>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input placeholder="请输入邮箱" v-model="searchForm.Email" size="small"/>
          </el-form-item>
          <el-form-item label="学历" prop="educational_background">
            <el-input placeholder="请输入学历" v-model="searchForm.EducationalBackground" size="small"/>
          </el-form-item>
          <el-form-item label="身份" prop="standing">
            <el-input placeholder="请输入身份" v-model="searchForm.Standing" size="small"/>
          </el-form-item>
          <el-form-item label="参与方式" prop="participation_mode">
            <el-input placeholder="请输入参与方式" v-model="searchForm.ParticipationMode" size="small"/>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select v-model="searchForm.Status" placeholder="请选择状态" size="small">
              <el-option
                v-for="(item, index) in pageData.Select.Status"
                :key="index"
                :label="item"
                :value="index*1">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>
    <!--    搜索区域结束   -->

    <!--    分割线-->
    <el-divider></el-divider>

    <!-- 弹窗区域开始 -->
    <el-dialog
      :title="this.scene === 1 ? '新增' : '编辑'"
      :visible.sync="showDialog"
      width="50%"
      :before-close="cancelDialog">
      <!-- 用户的表单信息 -->
      <el-form
        ref="form"
        :inline="false"
        :model="form"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="姓名" prop="Name">
          <el-input placeholder="请输入姓名" v-model="form.Name"/>
        </el-form-item>
        <el-form-item label="手机" prop="Phone">
          <el-input placeholder="请输入手机" v-model="form.Phone"/>
        </el-form-item>
        <el-form-item label="网站域名" prop="Domain">
          <el-input placeholder="请输入网站域名" v-model="form.Domain"/>
        </el-form-item>
        <el-form-item label="标题" prop="Thesis">
          <el-input placeholder="请输入标题" v-model="form.Thesis"/>
        </el-form-item>
        <el-form-item label="邮箱" prop="Email">
          <el-input placeholder="请输入邮箱" v-model="form.Email"/>
        </el-form-item>
        <el-form-item label="pdf" prop="Pdf">
          <el-input placeholder="请输入pdf" v-model="form.Pdf"/>
        </el-form-item>
        <el-form-item label="word" prop="Word">
          <el-input placeholder="请输入word" v-model="form.Word"/>
        </el-form-item>
        <el-form-item label="学历" prop="EducationalBackground">
          <el-input placeholder="请输入学历" v-model="form.EducationalBackground"/>
        </el-form-item>
        <el-form-item label="身份" prop="Standing">
          <el-input placeholder="请输入身份" v-model="form.Standing"/>
        </el-form-item>
        <el-form-item label="参与方式" prop="ParticipationMode">
          <el-input placeholder="请输入参与方式" v-model="form.ParticipationMode"/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="cancelDialog">取 消</el-button>
          <el-button type="primary" @click="submitClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 弹窗区域结束 -->

    <!-- 表头按钮区域开始 -->
    <div class="manage-header">
      <div>
        <el-button size="mini" class="el-icon-search" type="success" @click="searchClick" plain>搜索</el-button>
        <el-button size="mini" class="el-icon-refresh-right" type="warning" @click="resetClick" plain>重置</el-button>
      </div>
    </div>
    <!-- 表头按钮区域结束 -->

    <!-- 表格 -->
    <el-table
      :data="pageData.List"
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      border
      v-loading="tableLoad">
<!--      <el-table-column prop="Id" label="id"/>-->
      <el-table-column prop="TeacherText" label="所属老师"/>
      <el-table-column prop="Name" label="姓名"/>
      <el-table-column prop="Phone" label="手机"/>
      <el-table-column prop="Domain" label="网站域名">
        <template v-slot="{row}">
          <a :href="`https://${row.Domain}/`" target="_blank">{{row.Domain}}</a>
        </template>
      </el-table-column>
      <el-table-column prop="Thesis" label="标题" class="thesis">
        <template v-slot="{row}">
          <i class="el-icon-document-copy" @click="copyClick(row)"></i>
        </template>
      </el-table-column>
      <el-table-column prop="Email" label="邮箱"/>
      <el-table-column label="Pdf">
        <template v-slot="{row}">
          <a v-if="row.Pdf" :href="row.Pdf" download="投稿.pdf" class="el-icon-download"></a>
        </template>
      </el-table-column>
      <el-table-column prop="Word" label="Word">
        <template v-slot="{row}">
          <a v-if="row.Word" :href="row.Word" download="投稿.word" class="el-icon-download"></a>
        </template>
      </el-table-column>
      <el-table-column prop="EducationalBackground" label="学历"/>
      <el-table-column prop="Standing" label="身份"/>
      <el-table-column prop="ParticipationMode" label="参与方式"/>
      <el-table-column prop="StatusText" label="状态"/>
      <el-table-column prop="CreateTime" label="创建时间"/>
<!--      <el-table-column prop="UpdateTime" label="修改时间"/>-->
      <el-table-column label="操作">
        <template v-slot="{row}">
          <div>
            <el-button size="mini" @click="handleClick(row)" type="warning" v-if="row.Status === 1">核销</el-button>
            <!--            <el-button size="mini" @click="editClick(row)">编辑</el-button>-->
            <!--            <el-button size="mini" @click="deleteClick(row)" type="danger">删除</el-button>-->
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div>
      <el-pagination
        layout="prev, pager, next"
        :total="pageData.Total"
        @current-change="pagingClick"/>
    </div>
  </div>
</template>

<style>
.el-table .cell {
  text-align: center;
}
.el-icon-document-copy{
  cursor: pointer;
}
.el-icon-download{
  text-decoration: none;
  color: inherit; /* 继承父元素的颜色 */
}
</style>
<script>
import '@/assets/static/css/base.css'
import service from '@/service/Business/ContributeService'

export default service
</script>
